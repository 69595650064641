import React, {useContext, useEffect, useState} from 'react';
import '../../styles/pages/Connections/Endpoint.scss';
import {abbreviateNumber} from "../../util/format";
import {mapIcons} from "./config/iconMap";
import ConnectionsLineChart from "./ConnectionsLineChart";
import {getEndPointConnections} from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import {Loader} from "../../components/Loader/Loader";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import Tooltip from "../../components/Tooltip/Tooltip";
import {getTrendDifference} from "./config/trend";
import DisabledChart from "./DisabledChart";
import {hasAdminAccess} from "../../util/userControl"
import MailButton from "../../components/MailButton/MailButton";
import NotFound from "../../navigation/NotFound";
import {addContractedLine} from "./config/helper";
import { captureSentryError } from '../../util/sentry';
import {useNavigate} from "react-router-dom";
import {endpointFilterConfig} from "../Hosts/filterconfig";

const EndpointConnection = ({onInvestigationClick, isMobileOnly, createEmail}) => {
  const [userData] = useContext(UserData);
  const [connectionsData, setConnectionsData] = useState({})
  const [icons, setIcons] = useState({})
  const [remainingEndpoints, setRemainingEndpoints] = useState(0)
  const [endpointLoading, setEndpointLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [fetchError, setFetchError] = useState(false);
  const [contractedAmount,setContractedAmount]= useState(0)
  const [chartColors, setChartColors] = useState({active_endpoints: "#99E100", contracted: 'grey'});
  const navigate = useNavigate();
  const mapValues = {
    "Contracted": {field: 'contracted_endpoints'},
    "Events": {field: 'total_events'},
    "Investigations": {field: 'investigation_count'}
  }

  useEffect(() => {
    const getConnections = async () => {
      setFetchError(false);
      setEndpointLoading(true)
      try {
        let connectionsResponse = await getEndPointConnections(userData?.tenant)
        if (connectionsResponse?.active_endpoints) {
          let totalActiveEndpoints = Object.values(connectionsResponse.active_endpoints).reduce((a, b) => a + b, 0)
          let abbreviatedEndpoints = abbreviateNumber(totalActiveEndpoints, 0)
          let contractedEndpoints = connectionsResponse?.contracted_endpoints || 0
          contractedEndpoints > connectionsResponse?.trend?.data?.active_endpoints[0][1] ?
            setChartColors({...chartColors, active_endpoints: "#99E100"}) :
            setChartColors({...chartColors, active_endpoints: "red"})
          addContractedLine(contractedEndpoints, connectionsResponse, 'active_endpoints')
          setCount(abbreviatedEndpoints)
          setRemainingEndpoints(contractedEndpoints - totalActiveEndpoints)
          setIcons(connectionsResponse.active_endpoints)
          setContractedAmount(contractedEndpoints)
        }
        setConnectionsData(connectionsResponse)
      } catch (e) {
        captureSentryError(e, userData, "getEndPointConnections API in Endpoint.js");
        setFetchError(true);
      } finally {
        setEndpointLoading(false)
      }
    }

    userData?.tenant && !isMobileOnly && getConnections()
    isMobileOnly && setIcons({Linux: '', Mac: '', Windows: ''})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, isMobileOnly]);

  const navigateToEndpointsPage = (OS) => {
    if(!isMobileOnly){
      const config = [...endpointFilterConfig];
      if(OS) config[0].OS.filters[OS] = true;
      navigate("/connections/endpoints", { state: { filters: config } });
    }
  };

  return (
    <tr className={"connections-row endpoint"}>
      {!endpointLoading ?
        fetchError ? <NotFound isError dataError className="panel service"/>
          :
          <>
            <td className={"connection-type"}>
              <Tooltip content={tooltipMapping.serviceStatus.endpoint} className={"title-tooltip"} direction={"right"}>
                <p
                  className={
                    `is-connected ${isMobileOnly ? "disabled" : "clickable-title"}`
                  }
                  onClick={() => navigateToEndpointsPage()}
                >
                  ENDPOINT
                </p>
              </Tooltip>
              {connectionsData?.active_endpoints && <p className={"count"}>{isMobileOnly ? '' : count}</p>}
              {isMobileOnly && hasAdminAccess(userData?.role) &&
              <MailButton
                label={"ADD SERVICE »"}
                mailto={createEmail('endpoint')}
              />}
            </td>
            <td>
              <span className={"circle " + (!count ? "grey" : connectionsData?.status)}/>
            </td>
            <td className={"service-icons"}>
              {icons && Object.entries(icons).map((elem, i) => {
                  return (
                  <p
                    key={i}
                    className={
                      "active-endpoint" + (isMobileOnly ? " disabled" : " clickable")
                    }
                    onClick={() => navigateToEndpointsPage(elem[0])}
                  >
                    <img alt={elem} src={mapIcons(elem[0].toLowerCase())} />
                    <span>{elem[1]}</span>
                  </p>
                  )
                }
              )}
            </td>
            <td>
              {remainingEndpoints < 0 &&
              getTrendDifference(remainingEndpoints)
              }
            </td>
            <td className={"connection-data"}>
              {Object.entries(mapValues).map((elem, index) => {
                let field = elem[1].field
                let value = connectionsData[field]
                if (elem[0] === "Contracted" && connectionsData[field] === null) return ''
                let hasInvestigationLink = elem[0] === "Investigations" && value > 0
                return <div key={index} className={"display-values " + elem[0] + (hasInvestigationLink ? ' pointer' : '')}>
                  <p className={"name"}>{elem[0]}</p>
                  <p
                    className={"value " + (hasInvestigationLink ? ' link' : '')}
                    onClick={() => hasInvestigationLink && onInvestigationClick('Endpoint')}
                  >{!isMobileOnly ? abbreviateNumber(value, 0) : ''}</p>
                </div>
              })}
            </td>
            <td className={"chart-container" + (isMobileOnly ? ' disabled' : '')}>
              {connectionsData?.trend?.data && !isMobileOnly ?
                <ConnectionsLineChart
                  contractedAmount={contractedAmount}
                  displayName={'endpoint'}
                  data={connectionsData.trend.data}
                  colors={chartColors}
                /> : isMobileOnly &&
                <DisabledChart
                  data={[
                    {name: 'Contracted', values: []},
                    {name: 'Actual', values: []},
                    {name: 'Disconnected', values: []}
                    ]}
                  colors={{Contracted: 'green', Actual: 'orange', Disconnected: 'red'}}
                />}
            </td>
          </> : <td><Loader/></td>}
    </tr>
  )
}

export default EndpointConnection;